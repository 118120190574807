import React from 'react';
import './About.css';
import { FaHandshake, FaUserTie, FaBook } from 'react-icons/fa';
import ScrollAnimation from 'react-animate-on-scroll';

const About = () => {
  const boardMembers = [
    {
      name: "Mr. R. Ravindra",
      image: "/images/directors/ravindra.jpg",
      titles: [
        "Executive Director, Sugandhini Publications Pvt. Ltd.",
        "Chairperson, Arunodaya Sugandhini School of Education",
        "President, Sri Venkateshwara Trust, Ballari"
      ]
    },
    {
      name: "Mrs. Sugandhini Naidu .B",
      image: "/images/directors/sugandhini.jpg",
      titles: [
        "Managing Director and Author, Sugandhini Publications Pvt. Ltd.",
        "Secretary, Sri Venkateshwara Trust, Ballari",
        "Director, Founder and CEO of Arunodaya Sugandhini School of Education"
      ]
    },
    {
      name: "Mr. Boyapati Vishnu Vardhan",
      image: "/images/directors/vishnu.jpg",
      titles: [
        "Executive Director, Sugandhini Publications Pvt. Ltd.",
        "Director, Abhayanjaneya Infra Pvt. Ltd., Ballari"
      ]
    }
  ];

  const operationsTeam = [
    {
      name: "Mr. Ramaraju. V",
      role: "Editor - Head of Operations",
      image: "/images/operations/ramaraju.jpg"
    },
    {
      name: "Mr. Y. Veerabhadrappa",
      role: "DTP Team",
      image: "/images/operations/veerabhadrappa.jpg"
    }
  ];

  const contributors = [
    "Mr. Syed Saifulla Ashrafi, Ballari",
    "Mrs. Sumathi Ravichander, Senior Academician, Chennai / Bengaluru",
    "Mr. Venkatesh Ramachandrappa, DDPI, Vijayanagar Dist.",
    "Mr. Udeda Basavaraj, B.Sc, M.A, Member - faculty English Department",
    "Mr. Suresh Ramamurthi, Senior Mentor and Executive mBIT Coach",
    "Mr. Sidiginamale Basavaraj, Rtd. Superintendent V.V. Sangha",
    "Mrs. Dr. Subhashini, Shankramma Memorial Building",
    "Mr. Channa Basavaraj .S, Founder - Vebsters Enterprise Pvt. Ltd.",
    "Mr. Vijayendra Ranga Prasad, MA, M Phil.",
    {
      title: "We also acknowledge and thank all the people who have contributed in our endeavours for the completion of Kannada Sampattu Titles.",
      names: [
        {
          left: "Thatha - Hussain Sab,",
          right: "Swamy - B. Lakshmi Narayana,"
        },
        {
          left: "Mr. Joshua,",
          right: "Mr. S.G. Praveen,"
        },

        {
          left: "Mr. Venkata Narayana C, Chartered Accountant",
          right: ""
        }
      ],
      
      bankTeam: {
        title: "Thanks to State Bank of Indias' Team:",
        description: "Govt. Business Branch, Ballari."
      },
      footer: [
        "We remain greatly indebted to all the aforesaid and to all those who have put their sincere efforts and rendered their services in making our Publications reach in the hands of the Students successfully.",
        "Thank you one and all"
      ]
    }
  ];

  const BoardCard = ({ member }) => {
    return (
      <div className="board-card">
        <div className="board-profile-image">
          <img 
            src={process.env.PUBLIC_URL + member.image} 
            alt={member.name}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${process.env.PUBLIC_URL}/images/default-profile.jpg`;
            }}
          />
        </div>
        <h3>{member.name}</h3>
        <ul>
          {member.titles.map((title, index) => (
            <li key={index}>{title}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="about-page">
      <section className="about-hero">
        <div className="hero-content">
          <h1>About Us</h1>
          <p className="tagline">Building Excellence in Education Since 2022</p>
          <div className="hero-overlay"></div>
        </div>
      </section>

      <section className="board-section">
        <h2><FaUserTie className="section-icon" /> Board of Directors</h2>
        <div className="board-grid">
          {boardMembers.map((member, index) => (
            <BoardCard key={index} member={member} />
          ))}
        </div>
      </section>

      <ScrollAnimation animateIn="slide-up">
        <section className="operations-section">
          <h2><FaBook className="section-icon" /> Operations Team</h2>
          <div className="operations-grid">
            {operationsTeam.map((member, index) => (
              <div key={index} className="operations-card">
                <div className="operations-profile-image">
                  <img 
                    src={process.env.PUBLIC_URL + member.image}
                    alt={member.name}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `${process.env.PUBLIC_URL}/images/default-profile.jpg`;
                    }}
                  />
                </div>
                <h3>{member.name}</h3>
                <p>{member.role}</p>
              </div>
            ))}
          </div>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fade-in">
        <section className="acknowledgement-section">
          <h2><FaHandshake className="section-icon" /> Acknowledgements</h2>
          {contributors.map((contributor, index) => (
            <div key={index} className="acknowledgement-content">
              {typeof contributor === 'string' ? (
                <p>{contributor}</p>
              ) : (
                <>
                  <h3 className="acknowledgement-title">{contributor.title}</h3>
                  <div className="contributors-grid">
                    {contributor.names.map((pair, idx) => (
                      <div key={idx} className="name-pair">
                        <div className="left-name">{pair.left}</div>
                        {pair.right && <div className="right-name">{pair.right}</div>}
                      </div>
                    ))}
                  </div>
                  {contributor.subtitle && (
                    <p className="subtitle">{contributor.subtitle}</p>
                  )}
                  {contributor.bankTeam && (
                    <div className="bank-team">
                      <strong>{contributor.bankTeam.title}</strong> {contributor.bankTeam.description}
                    </div>
                  )}
                  <div className="footer-text">
                    {contributor.footer.map((text, idx) => (
                      <p key={idx}>{text}</p>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </section>
      </ScrollAnimation>
    </div>
  );
};

export default About;
