const API_URL = process.env.REACT_APP_API_URL || '';

const handleLogin = async (credentials) => {
  try {
    const trimmedCredentials = {
      email: credentials.email.trim(),
      password: credentials.password.trim()
    };

    const response = await fetch(`${API_URL}/api/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(trimmedCredentials)
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.message || 'Login failed. Please check your credentials.');
    }

    const data = await response.json();
    
    if (data.token) {
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
    }

    return data.user;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

const handleSignup = async (userData) => {
  try {
    const trimmedData = {
      email: userData.email.trim(),
      password: userData.password.trim(),
      name: userData.name?.trim() || '',
      type: userData.type || 'Student'
    };

    const response = await fetch(`${API_URL}/api/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(trimmedData)
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.message || 'Sign up failed. Please try again.');
    }

    const data = await response.json();
    
    if (data.token) {
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
    }

    return data.user;
  } catch (error) {
    console.error('Signup error:', error);
    throw error;
  }
};

export { handleLogin, handleSignup }; 