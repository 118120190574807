import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Book.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { FaBook, FaShoppingCart, FaInfoCircle, FaBookOpen } from 'react-icons/fa';
import LoadingSpinner from '../components/LoadingSpinner';
import { useCart } from '../context/CartContext';
import { useAuth } from '../context/AuthContext';

const Book = () => {
  const { dispatch } = useCart();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('description');
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleAddToCart = (book) => {
    if (!user) {
      alert('Please log in to add items to the cart.');
      return;
    }

    const bookItem = {
      id: book.id,
      title: book.title,
      price: book.price,
      image: book.coverImage,
      author: book.author,
    };

    dispatch({
      type: 'ADD_TO_CART',
      payload: bookItem,
    });

    const confirmAdd = window.confirm('Book added to cart! Would you like to view your cart?');
    if (confirmAdd) {
      navigate('/cart');
    }
  };

  const handleBuyNow = (book) => {
    if (!user) {
      alert('Please log in to purchase.');
      return;
    }

    const bookItem = {
      id: book.id,
      title: book.title,
      price: parseFloat(book.price.replace('₹', '')),
      image: book.image || '/book_cover.jpg',
      quantity: 1,
      category: book.category
    };

    dispatch({
      type: 'ADD_TO_CART',
      payload: bookItem
    });
    
    navigate('/cart');
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const books = [
    {
      id: 1,
      title: "Kannada Sampatthu- Level 1",
      category: "Pre-School",
      price: "₹499",
      description: "Complete course in Kannada for kids of Foundation Level (Preschool to Grade-II)",
      image: "/book_cover.jpg",
      preface: `This book is designed as per the NEP guidelines prescribed by the Government of India to provide a complete course in Kannada for kids of Foundation Level that is from Preschool to Grade - II, three years to seven years of age group, considering their age and the learning abilities.
  
  Education not only sculpts the future of the students, but also encourages the growth of new ideas inculcating social rights and responsibilities with a commitment to oneself and the society.
  
  The Curriculum mapped is to design a bright future for the students. This book aims to introduce the basics of Kannada Language in a systematic approach, with integrated Text Book cum Work Book and also making it a 360-degree learning book by providing bilingual digital content of audio and video of the complete text book, which will facilitate in understanding the concepts easily even for Non-Kannadigas with the English translation of the content.`,
      features: [
        "NEP 2020 Aligned",
        "Bilingual Content",
        "Digital Resources",
        "Interactive Workbook",
        "5-Level Learning System"
      ]
    },
    {
      id: 2,
      title: "Kannada Sampatthu - Level 2",
      category: "LKG",
      price: "₹549",
      description: "Comprehensive Kannada learning for Intermediate Level students (Grade III-V)",
      image: "/book_cover_2.jpg",
      preface: `Building upon the foundation level, this book extends the learning journey with advanced concepts...`,
      features: [
        "NEP 2020 Aligned",
        "Advanced Concepts",
        "Digital Resources",
        "Interactive Exercises",
        "5-Level Learning System"
      ]
    },
    {
      id: 3,
      title: "Kannada Sampatthu - Level 3",
      category: "UKG",
      price: "₹599",
      description: "Advanced Kannada course for higher grade students (Grade VI-VIII)",
      image: "/book_cover_3.jpg",
      preface: `An advanced curriculum designed to perfect Kannada language skills with comprehensive coverage...`,
      features: [
        "NEP 2020 Aligned",
        "Expert Level Content",
        "Digital Learning Hub",
        "Practice Workbook",
        "5-Level Learning System"
      ]
    }
  ];

  const authorsNote = (
    <div className="authors-note-section">
      <div className="note-header">
        <div className="title-center">
          <h2>Author's Note</h2>
          <p className="sanskrit-text">Sarve Janaa Sukhino Bhavantu</p>
          <p className="quote">" We reap what we sow "</p>
        </div>
      </div>

      <div className="note-content">
        <p className="first-paragraph"><span className="drop-cap">T</span>his applies to all walks of our life...</p>
        
        <p>The idea behind to structure this Kannada Sampathu, a Conceptual / Bilingual Integrated Text Book cum Work Book with Digital Content of Speech Drills (audio & video) Self-Assessment and Accomplishment is a remedy for the struggle and pain faced by the parents, educators, mentors and students to accomplish literacy in Kannada especially Non-Kannadigas, who absolutely do not have Kannada background, but studying Kannada is mandatory as part of schooling in Karnataka.</p>
        
        <p>With the passion of teaching young kids and educating parents, educators, mentors and students for the past 18 years, I could internalize the need of the right kind of teaching and learning methodologies which can ease the process of studying Kannada. I could see the stages of learning becoming so easy with the organized steps of teaching.</p>
        
        <div className="learning-stages">
          <p>The learning stages of the students :</p>
          <ul>
            <li><span className="level-1">Level-1 Crawlers - Preschool</span> - Identify the letters and picture reading.</li>
            <li><span className="level-2">Level-2 Walkers - LKG</span> - Able to form the letters upto Swaragalu.</li>
            <li><span className="level-3">Level-3 Runners - UKG</span> - Capable of writing simple words.</li>
            <li><span className="level-4">Level-4 Flyers - 1<sup>st</sup>std</span> - Ability to read and write simple sentences.</li>
            <li><span className="level-5">Level-5 Gliders - 2<sup>nd</sup>std</span> - Accomplish basic fundamentals and confident to read and write independently.</li>
          </ul>
        </div>
        
        <p>These stages will empower the children to achieve the foundation skills of NEP-2020 in their early childhood with ease.</p>
        
        <p>I am grateful to all those people who have inspired me to pen-down my experiences in teaching young kids and how it can make a vast difference in the field of education of early childhood and thank all of them whole heartedly.</p>
        
        <p>The divine blessings of <span className="deity">Lord Ganesha, Lord Venkateshwara, Mahavatar Babaji</span>.</p>
        
        <div className="parents-section">
          <div className="note-header">
            <div className="parents-image">
              <img 
                src={process.env.PUBLIC_URL + '/images/parents.jpg'} 
                alt="Parents"
                className="parents-photo"
              />
            </div>
            <div className="note-content">
              <p>With immense love and affection, I dedicate my first book <span className="book-title">Kannada Sampatthu</span> to my beloved parents MY LIVING GOD <span className="parent-name">Late Shri B. Venu Gopal</span> and my beloved Mother <span className="parent-name">Smt. B. Geetha</span>, what I am today is just because of them. With sheer love, encouragement and blessings of them I wish to author many books in the years ahead.</p>
            </div>
          </div>

          <div className="sanskrit-closing">
            <p>Samasta Sanmangalaani Bhavantu</p>
            
          </div>

          <div className="author-signature">
            <img 
              src={process.env.PUBLIC_URL + '/images/author-signature.jpg'} 
              alt="Author"
              className="author-photo"
            />
            <p className="thank-you">Thank you</p>
            <p className="author">Author</p>
            <p className="author-name">Sugandhini Naidu .B</p>
          </div>
        </div>
      </div>
    </div>
  );

  const upcomingBooks = (
    <div className="upcoming-books">
      <h2>Upcoming Books</h2>
      <div className="book-grid">
        {/* First Book Card */}
       
  
        {/* Coming Soon Card */}
        <div className="book-card coming-soon">
          <div className="coming-soon-content">
            <FaBook className="coming-soon-icon" />
            <h3>Coming Soon</h3>
            <p>New titles are on the way!</p>
            <span className="stay-tuned">Stay tuned for updates</span>
          </div>
        </div>
      </div>
    </div>
  );
  
  return (
    <div className="book-page">
      <ScrollAnimation animateIn="fadeIn">
        <div className="book-hero">
          <h1>Our Publications</h1>
          <p className="hero-subtitle">Excellence in Kannada Language Learning</p>
        </div>
  
        <div className="book-content">
          <div className="book-list-container">
            {books.map(book => (
              <div key={book.id} className="book-showcase">
                <span className="level-indicator">{book.category}</span>
                <div className="book-preview">
                  <div className="book-3d">
                    <img src={book.image} alt={book.title} onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'https://via.placeholder.com/150';
                    }}/>
                  </div>
                  <div className="add-to-cart-container">
                    <button 
                      className="add-to-cart-btn"
                      onClick={() => handleAddToCart(book)}
                    >
                      <FaShoppingCart /> Add to Cart - {book.price}
                    </button>
                    <button 
                      className="buy-now"
                      onClick={() => handleBuyNow(book)}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
  
                <div className="book-info">
                  <h2>{book.title}</h2>
                  <div className="book-tabs">
                    <button 
                      className={`tab ${activeTab === 'description' ? 'active' : ''}`}
                      onClick={() => setActiveTab('description')}
                    >
                      <FaInfoCircle /> Description
                    </button>
                    <button 
                      className={`tab ${activeTab === 'preface' ? 'active' : ''}`}
                      onClick={() => setActiveTab('preface')}
                    >
                      <FaBookOpen /> Preface
                    </button>
                  </div>
  
                  <div className="tab-content">
                    {activeTab === 'description' ? (
                      <div className="description-content">
                        <p>{book.description}</p>
                        <div className="features-grid">
                          {book.features.map((feature, index) => (
                            <div key={index} className="feature-item">
                              <FaBook /> {feature}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="preface-content">
                        {book.preface.split('\n\n').map((paragraph, index) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
  
          <div className="authors-note-container">
            {authorsNote}
          </div>
  
          <div className="upcoming-books-section">
            {upcomingBooks}
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};
  
export default Book; 
  