import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Book from './pages/Book';
import About from './pages/About';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Author from './components/Author';
import Footer from './components/Footer';
import Header from './components/Header';
import AdminDashboard from './components/AdminDashboard';
import UserProfileDetails from './components/UserProfileDetails';
import EditProfile from './components/EditProfile';
import Cart from './components/Cart';
import ScrollToTop from './components/ScrollToTop';
import { AuthProvider, useAuth } from './context/AuthContext';
import { CartProvider } from './context/CartContext';

function App() {
  const { user } = useAuth();

  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <ScrollToTop />
          <div className="App">
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/book" element={<Book />} />
              <Route path="/about" element={<About />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/author" element={<Author />} />
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/cart" element={<Cart />} />
              <Route 
                path="/profile" 
                element={user ? <UserProfileDetails /> : <Navigate to="/" replace />} 
              />
              <Route 
                path="/edit-profile" 
                element={user ? <EditProfile /> : <Navigate to="/" replace />} 
              />
            </Routes>
            <Footer />
          </div>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;