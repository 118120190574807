import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';
import { FaUser, FaSignOutAlt, FaEdit, FaBook } from 'react-icons/fa';

const UserProfile = ({ user, onLogout }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    setIsDropdownOpen(false);
    navigate(route);
  };

  const handleLogoutClick = () => {
    setIsDropdownOpen(false);
    onLogout();
    navigate('/');
  };

  if (!user || !user.name) {
    return null;
  }

  return (
    <div className="profile-container">
      <button 
        className="profile-avatar"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {user.name.charAt(0).toUpperCase()}
      </button>

      {isDropdownOpen && (
        <div className="profile-dropdown">
          <div className="profile-header">
            <div className="profile-avatar-large">{user.name.charAt(0).toUpperCase()}</div>
            <div className="profile-info">
              <h3>{user.name}</h3>
              <p>{user.email}</p>
              <span className="user-type">{user.type}</span>
            </div>
          </div>

          <div className="profile-menu">
            <button className="menu-item" onClick={() => handleMenuClick('/profile')}>
              <FaUser /> My Profile
            </button>
            <button className="menu-item" onClick={() => handleMenuClick('/sessions')}>
              <FaBook /> My Sessions
            </button>
            <button className="menu-item" onClick={() => handleMenuClick('/edit-profile')}>
              <FaEdit /> Edit Profile
            </button>
            <button className="menu-item logout" onClick={handleLogoutClick}>
              <FaSignOutAlt /> Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile; 