import React, { useState } from 'react';
import './Contact.css';
import { FaEnvelope, FaMapMarkerAlt, FaClock, FaGlobe, FaFacebook, FaTwitter, FaYoutube, FaPhone } from 'react-icons/fa';
import ScrollAnimation from 'react-animate-on-scroll';

const Contact = () => {
  const [formData, setFormData] = useState({
    childName: '',
    age: '',
    parentName: '',
    email: '',
    preferredSession: ''
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setMessage('Registration successful!');
        setFormData({
          childName: '',
          age: '',
          parentName: '',
          email: '',
          preferredSession: ''
        });
      } else {
        setMessage('Registration failed. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    }
    setLoading(false);
  };

  return (
    <div className="contact-page">
      <div className="contact-hero">
        <div className="floating-elements">
          <div className="float-item envelope"></div>
          <div className="float-item star"></div>
          <div className="float-item pencil"></div>
          <div className="float-item heart"></div>
        </div>
        <h1>Book a Session</h1>
        <p className="hero-subtitle">Meet Our Authors & Explore the Magic of Books and more!</p>
      </div>

      <div className="contact-content">
        <div className="booking-form-section">
          <div className="static-form-container">
            <div className="form-header">
              <h2>Register for Author's Session</h2>
              <p>Join us for an interactive experience!</p>
              <div className="book-character">📬</div>
            </div>
            <form className="static-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Child's Name</label>
                <input 
                  type="text" 
                  name="childName"
                  value={formData.childName}
                  onChange={handleChange}
                  placeholder="Enter your child's name" 
                  required 
                />
              </div>
              <div className="form-group">
                <label>Age</label>
                <input 
                  type="number" 
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  min="3" 
                  max="12" 
                  placeholder="Child's age" 
                  required 
                />
              </div>
              <div className="form-group">
                <label>Parent's Name</label>
                <input 
                  type="text" 
                  name="parentName"
                  value={formData.parentName}
                  onChange={handleChange}
                  placeholder="Your name" 
                  required 
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input 
                  type="email" 
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your email" 
                  required 
                />
              </div>
              <div className="form-group">
                <label>Preferred Session</label>
                <select 
                  name="preferredSession"
                  value={formData.preferredSession}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a session</option>
                  <option value="morning">Morning Story Time (10 AM)</option>
                  <option value="afternoon">Afternoon Reading (2 PM)</option>
                  <option value="evening">Evening Session (4 PM)</option>
                </select>
              </div>
              <button type="submit" className="submit-btn" disabled={loading}>
                {loading ? 'Registering...' : 'Register Now 📖'}
              </button>
              {message && <div className="message">{message}</div>}
            </form>
          </div>
        </div>
      </div>

      <ScrollAnimation animateIn="fadeIn">
        <section className="reach-us-section">
          <div className="section-header">
            <h2>Feel Free To Reach Us</h2>
          </div>
          <div className="contact-info-grid">
            <div className="business-info">
              <div className="info-card">
                <h3><FaClock className="icon" /> Business Hours</h3>
                <p><FaEnvelope className="icon-small" /> support@sugandhinipublications.com</p>
                <p><FaPhone className="icon-small" /> 7349371744 / 7899192193</p>
              </div>
              <div className="location-info">
                <h3><FaMapMarkerAlt className="icon" /> LOCATION</h3>
                <div className="address">
                  <div className="address-item">
                    <h4>Head Office:</h4>
                    <p>Shankaramna Memorial Building, Bankers Colony, Shastri Nagar, Ballari-583103, KARNATAKA</p>
                  </div>
                  <div className="address-item">
                    <h4>Branch Office:</h4>
                    <p>Nagagowtana Palya, Kodigehalli Road, Bengaluru - 560062, KARNATAKA</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3851.0045765162727!2d76.91619127476227!3d15.158093363185474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb711e1099ddc07%3A0x1dd9ad23a0b8ace7!2sArunodaya%20Pre%20Primary%20School!5e0!3m2!1sen!2sin!4v1731126534656!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0, borderRadius: '20px' }}
                title="Sugandhini Publications Office Location"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="social-links">
          <a href="https://youtube.com/@sugandhinipublications?si=n8FqVf2szzn9C8wj" className="social-icon"><FaYoutube /></a>
            <a href="https://example.com" className="social-icon"><FaGlobe /></a>
            <a href="https://facebook.com/your-page" className="social-icon"><FaFacebook /></a>
            <a href="https://twitter.com/your-handle" className="social-icon"><FaTwitter /></a>
           
          </div>
        </section>
      </ScrollAnimation>
    </div>
  );
};

export default Contact; 