import React, { useState } from 'react';
import './Blog.css';
import { FaChild, FaBook, FaGraduationCap, FaHeart, FaStar } from 'react-icons/fa';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';


const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = [
    { id: 'all', name: 'All Posts', icon: <FaStar /> },
    { id: 'parenting', name: 'Parenting Tips', icon: <FaHeart /> },
    { id: 'education', name: 'Education', icon: <FaGraduationCap /> },
    { id: 'activities', name: 'Fun Activities', icon: <FaChild /> },
    { id: 'reading', name: 'Reading Corner', icon: <FaBook /> }
  ];

  const blogPosts = [
    {
      id: 1,
      category: 'parenting',
      title: 'Building Strong Reading Habits in Children',
      excerpt: 'Discover effective strategies to encourage your child\'s love for reading...',
      readTime: '5 min read',
      image: 'https://source.unsplash.com/800x600/?children,reading',
      pdfUrl: `${process.env.PUBLIC_URL}/pdfs/reading-habits.pdf`
    },
    {
      id: 2,
      category: 'education',
      title: 'Early Learning Techniques',
      excerpt: 'Effective methods to support early childhood development...',
      readTime: '4 min read',
      image: 'https://source.unsplash.com/800x600/?education,children',
      pdfUrl: `${process.env.PUBLIC_URL}/pdfs/learning-techniques.pdf`
    },
    {
      id: 'about-sugandhini',
      title: 'About Sugandhini Publications',
      excerpt: 'Learn more about our mission, values, and commitment to quality educational materials...',
      readTime: '3 min read',
      category: 'all',
      pdfUrl: `${process.env.PUBLIC_URL}/pdfs/about-sugandhini.pdf`
    }
  ];

  return (
    <div className="blog-page">
      <section className="blog-hero">
        <div className="floating-books">
          {/* Animated floating book elements */}
          <div className="book book-1"></div>
          <div className="book book-2"></div>
          <div className="book book-3"></div>
        </div>
        <div className="hero-content">
          <h1>Learning & Growing Together</h1>
          <p className="tagline">Explore our collection of articles on parenting, education, and child development</p>
        </div>
      </section>

      <div className="category-nav">
        {categories.map(category => (
          <button
            key={category.id}
            className={`category-btn ${selectedCategory === category.id ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category.id)}
          >
            {category.icon}
            <span>{category.name}</span>
          </button>
        ))}
      </div>

      <div className="blog-content">
        <ScrollAnimation animateIn="fade-in">
          <div className="featured-post">
            {/* Featured blog post */}
          </div>
        </ScrollAnimation>

        <div className="posts-grid">
          {blogPosts
            .filter(post => selectedCategory === 'all' || post.category === selectedCategory)
            .map(post => (
              <ScrollAnimation key={post.id} animateIn="bounce-in">
                <article className="blog-card">
                  <div className="card-image">
                    <img src={post.image} alt={post.title} />
                  </div>
                  <div className="card-content">
                    <h3>{post.title}</h3>
                    <p>{post.excerpt}</p>
                    <div className="card-footer">
                      <span className="read-time">{post.readTime}</span>
                      <a 
                        href={post.pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="read-more"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </article>
              </ScrollAnimation>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Blog; 