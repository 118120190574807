import React, { useRef, useEffect } from 'react';
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { useAuth } from '../context/AuthContext';
import Checkout from './Checkout';

const Cart = () => {
  const { state, dispatch } = useCart();
  const { user } = useAuth();
  const cartRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const fetchCart = async () => {
        try {
          const response = await fetch(`/api/cart/${user._id}`);
          const data = await response.json();
          if (data.items) {
            dispatch({
              type: 'SET_CART',
              payload: data.items
            });
          }
        } catch (error) {
          console.error('Error fetching cart:', error);
        }
      };
      fetchCart();
    }
  }, [user, dispatch]);

  const updateQuantity = async (item, newQuantity) => {
    if (newQuantity < 1) return;
    try {
      const response = await fetch('/api/cart/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user._id,
          item: { ...item, quantity: newQuantity }
        })
      });
      
      if (response.ok) {
        dispatch({
          type: 'UPDATE_QUANTITY',
          payload: { id: item.id, quantity: newQuantity }
        });
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  const removeItem = async (item) => {
    if (window.confirm('Are you sure you want to remove this item from cart?')) {
      try {
        const response = await fetch('/api/cart/update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user._id,
            item: { ...item, quantity: 0 }
          })
        });
        
        if (response.ok) {
          dispatch({
            type: 'REMOVE_FROM_CART',
            payload: item
          });
        }
      } catch (error) {
        console.error('Error removing item:', error);
      }
    }
  };

  if (!user) {
    return (
      <div className="empty-cart">
        <h2>Please login to view your cart</h2>
        <button 
          className="submit-button"
          onClick={() => alert('Redirecting to login/signup page')}
        >
          Login/Signup
        </button>
      </div>
    );
  }

  if (state.items.length === 0) {
    return (
      <div className="empty-cart">
        <h2>Your cart is empty</h2>
        <button 
          className="submit-button"
          onClick={() => navigate('/book')}
        >
          Browse Books
        </button>
      </div>
    );
  }

  return (
    <div className="cart-container">
      <h2>Your Shopping Cart</h2>
      <div className="cart-list">
        {state.items.map((item) => (
          <div key={item.id} className="cart-item">
            <img src={item.image} alt={item.title} className="cart-item-image" />
            <div className="cart-item-details">
              <h3>{item.title}</h3>
              <p>Author: {item.author}</p>
              <p>Price: ₹{item.price}</p>
              <div className="quantity-controls">
                <button 
                  className="quantity-btn"
                  onClick={() => updateQuantity(item, item.quantity - 1)}
                >
                  <FaMinus />
                </button>
                <span className="quantity-value">{item.quantity}</span>
                <button 
                  className="quantity-btn"
                  onClick={() => updateQuantity(item, item.quantity + 1)}
                >
                  <FaPlus />
                </button>
              </div>
              <button 
                className="remove-btn"
                onClick={() => removeItem(item)}
              >
                <FaTrash /> Remove
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="checkout-section">
        <h3>Total: ₹{state.total}</h3>
        <Checkout />
      </div>
    </div>
  );
};

export default Cart;