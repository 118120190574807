import React, { useState } from 'react';
import './Login.css';
import { FaEnvelope, FaLock, FaTimes, FaEye, FaEyeSlash } from 'react-icons/fa';
import UserProfile from './UserProfile';
import { handleLogin, handleSignup } from '../services/authService'; // Correct import

const Login = ({ isOpen, onClose, onLoginSuccess, onLogout }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if it's sign-up and handle accordingly
    try {
      const credentials = { email, password };

      // Handle Sign Up
      if (isSignUp) {
        if (password !== confirmPassword) {
          setError('Passwords do not match');
          return;
        }
        const newUser = await handleSignup(credentials);
        setUser(newUser);
        setIsLoggedIn(true);
        onLoginSuccess(newUser);
        onClose();
      } else {
        // Handle Sign In
        const loggedInUser = await handleLogin(credentials);
        setUser(loggedInUser);
        setIsLoggedIn(true);
        onLoginSuccess(loggedInUser);
        onClose();
      }
    } catch (error) {
      console.error('Authentication failed:', error);
      setError(error?.message || 'An error occurred, please try again');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setIsLoggedIn(false);
    setEmail('');
    setPassword('');
    onLoginSuccess(null);
    if (onLogout) onLogout();
  };

  if (!isOpen) return null;

  return isLoggedIn ? (
    <UserProfile user={user} onLogout={handleLogout} />
  ) : (
    <div className="login-overlay">
      <div className="login-modal">
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>

        <div className="login-content">
          <h2>{isSignUp ? 'Create Account' : 'Welcome Back'}</h2>
          <p className="login-subtitle">
            {isSignUp
              ? 'Join our educational community today!'
              : 'Sign in to access your account'}
          </p>

          <form onSubmit={handleSubmit} className="login-form">
            {error && <div className="error-message">{error}</div>}
            <div className="form-group">
              <FaEnvelope className="input-icon" />
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <FaLock className="input-icon" />
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>

            {isSignUp && (
              <div className="form-group">
                <FaLock className="input-icon" />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
            )}

            <button type="submit" className="submit-button">
              {isSignUp ? 'Sign Up' : 'Sign In'}
            </button>
          </form>

          <div className="login-footer">
            <p>
              {isSignUp
                ? 'Already have an account?'
                : "Don't have an account?"}
              <button
                className="toggle-button"
                onClick={() => setIsSignUp(!isSignUp)}
              >
                {isSignUp ? 'Sign In' : 'Sign Up'}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
