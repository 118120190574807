import React from 'react';
import './Author.css';
import { FaTrophy, FaGraduationCap, FaRunning, FaSchool, FaAward, FaMedal, FaHockeyPuck, FaSwimmer, FaStar, FaTv, FaUserTie } from 'react-icons/fa';

function Author() {
  const educationTimeline = [
    { year: "1990-1997", institution: "St.Philomena's High School, Ballari" },
    { year: "1994", institution: "Blossoms English Medium School, Madikeri" },
    { year: "1997-1999", institution: "Teresian College, Mysore (Pre-University)" },
    { year: "🥇2005", institution: " University Gold Medalist in B.Com final year in Business Law at ASM College Ballari." },
    { year: "1997-1999", institution: "Secured Post Graduate Diploma in Communicative English from KSOU, " },
    { year: "2002-2004", institution: "Sri Krishnadevaraya University, Ballari (M.A in English)" },
    { year: "2004-2005", institution: "Mohammadia College, Ballari (B.Ed)" },
    { year: "2020", institution: "Montitute, Wood Bridge USA (Montessori Diploma)" },
    { year: "2021", institution: "CPD College London (JPHUK British Version)" }
  ];

  return (
    <div className="author-page">
      <div className="author-hero">
        <div className="author-profile">
          <div className="author-image-container">
            <img 
              src={process.env.PUBLIC_URL + "/images/directors/sugandhini.jpg"}
              alt="Mrs. Sugandhini Naidu .B"
              className="author-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `data:image/svg+xml,${encodeURIComponent(
                  '<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">' +
                  '<rect width="100%" height="100%" fill="#f0f0f0"/>' +
                  '<text x="50%" y="50%" font-family="Arial" font-size="16" fill="#666" text-anchor="middle" dy=".3em">' +
                  'Author Image' +
                  '</text>' +
                  '</svg>'
                )}`;
              }}
            />
          </div>
          <h1>Mrs. Sugandhini Naidu .B</h1>
          <div className="credentials">
            M A, B.Ed, PGDCE, DMTT Montitute USA, JPHUK CPD College, London
          </div>
          <div className="current-roles">
            <p>Managing Director and Author, Sugandhini Publications Pvt. Ltd.</p>
            <p>Secretary, Sri Venkateshwara Trust, Ballari</p>
            <p>Founder and CEO, Arunodaya Sugandhini School of Education, Ballari</p>
          </div>
        </div>
      </div>

      <div className="author-content">
        <section className="contribution-section">
          <h2 className="contribution-title">Contribution in Education Sector</h2>
          <div className="contribution-grid">
            <div className="contribution-card">
              <p className="contribution-text">Enrolled as a Pre-School Mentor in 2005 at Pupil Tree School, Ballari for 2 years</p>
            </div>
            <div className="contribution-card">
              <p className="contribution-text">Pursued as Coordinator & Mentor at Genesis School, Ballari for 5 years</p>
            </div>
            <div className="contribution-card">
              <p className="contribution-text">Served as a President in LEO Club Ballari in 2014</p>
            </div>
            <div className="contribution-card">
              <p className="contribution-text">Raised as a Headmistress in a short span of 8 years</p>
            </div>
            <div className="contribution-card">
              <p className="contribution-text">Worked as a Director for Arunodaya School-Group of three V.V. Sangha's Primary Schools in Ballari District</p>
            </div>
            <div className="contribution-card">
              <p className="contribution-text">Worked as a Founder Principal of SK Modi National School, V.V. Sangha, Ballari for 9 years</p>
            </div>
            <div className="contribution-card">
              <p className="contribution-text">Secretary for Sri Venkateshwara Trust, Ballari in 2022</p>
            </div>
            <div className="contribution-card">
              <p className="contribution-text">Founder and CEO of Arunodaya Sugandhini School of Education, Pre-Primary and Primary School, Ballari</p>
            </div>
          </div>
        </section>

        <section className="career-timeline">
          <h2><FaSchool className="section-icon" /> Professional Journey</h2>
          <div className="timeline">
            <div className="timeline-item">
              <div className="year">2005-2007</div>
              <div className="description">Pre-School Mentor at Pupil Tree School, Ballari</div>
            </div>
            <div className="timeline-item">
              <div className="year">2007-2012</div>
              <div className="description">Coordinator & Mentor at Genesis School, Ballari</div>
            </div>
            <div className="timeline-item">
              <div className="year">2013-2022</div>
              <div className="description">Founder Principal at SK Modi National School, V.V. Sangha</div>
            </div>
            <div className="timeline-item">
              <div className="year">2022-Present</div>
              <div className="description">Secretary at Sri Venkateshwara Trust & Founder CEO at Arunodaya Sugandhini School</div>
            </div>
          </div>
        </section>

        <section className="achievements">
          <h2><FaTrophy className="section-icon" /> Notable Achievements</h2>
          <div className="achievement-cards">
            <div className="achievement-card">
              <FaRunning className="achievement-icon" />
              <h3>Guinness Book Attempt (1990)</h3>
              <p>Completed 1500 kms marathon through 11 districts for World Peace and Drug Abuse at age 6</p>
            </div>
            <div className="achievement-card">
              <FaTrophy className="achievement-icon" />
              <h3>National Sports Excellence</h3>
              <p>Represented State at Nationals in Hockey 9 times and South India against Singapore (2002)</p>
            </div>
            <div className="achievement-card">
              <FaAward className="achievement-icon" />
              <h3>Media Recognition</h3>
              <p>Featured in BBC NEWS London and interviewed in World of Sports at New Delhi (1993)</p>
            </div>
          </div>
        </section>

        <section className="sports-achievements">
          <ul className="sports-list">
            <li className="sports-item">
              <FaRunning className="sports-icon" />
              <p className="sports-text">Participated in Open Nationals at New Delhi in 1993 in Half Marathon 21 Kms was a part of 'World Of Sports' interview as Youngest Marathon Runner of 8 years 6 months.</p>
            </li>
            <li className="sports-item">
              <FaMedal className="sports-icon" />
              <p className="sports-text">Trained in SAI (Sports Authority Of India) Madikeri for 2 years at the age of 9 years as an Athlete.</p>
            </li>
            <li className="sports-item">
              <FaTrophy className="sports-icon" />
              <p className="sports-text">Qualified in State Womens' Hostel Mysore in the year 2000, Represented State at Nationals in Hockey 9 times inter School, Juniors and Womens' Hockey from the year 1999 to 2002.</p>
            </li>
            <li className="sports-item">
              <FaHockeyPuck className="sports-icon" />
              <p className="sports-text">Represented South India from KSWH (Karnataka State Womens' Hockey) in the year 2002 against Singapore at Chennai.</p>
            </li>
            <li className="sports-item">
              <FaSwimmer className="sports-icon" />
              <p className="sports-text">Certified By ASCA (Australian Swimming Coaching Association) at Bangalore. Worked as Swim Coach for 10 years at Police Gymkhana, Ballari.</p>
            </li>
            <li className="sports-item">
              <FaRunning className="sports-icon" />
              <p className="sports-text">Worked at Jindal Vidya Mandir as an Athletic Coach and secured 2 Gold Medals in CBSE South Zone at Hyderabad and represented CBSE Nationals at Ahmedabad, Gujarat 2003.</p>
            </li>
          
            <li className="sports-item">
              <FaAward className="sports-icon" />
              <p className="sports-text">Awarded General Thimmayya National Academy of Adventure - 1991.</p>
            </li>
            <li className="sports-item">
              <FaStar className="sports-icon" />
              <p className="sports-text">Invited as a Young Chief Guest for Children Film Festival at Ballari in 1991 at age of 7 years.</p>
            </li>
            <li className="sports-item">
              <FaTv className="sports-icon" />
              <p className="sports-text">Remarkable performance as a Youngest Childhood Marathon Runner - Interviewed in WORLD OF SPORTS at New Delhi in 1993 and Telecasted in Surabhi Doordarshan Channel.</p>
            </li>
            <li className="sports-item">
              <FaUserTie className="sports-icon" />
              <p className="sports-text">Invited as a Chief Guest for Rural National Womens' Hockey in 2016 at Ballari</p>
            </li>
          </ul>
        </section>

        <section className="education">
          <h2><FaGraduationCap className="section-icon" /> Educational Background</h2>
          <div className="education-timeline">
            {educationTimeline.map((edu, index) => (
              <div key={index} className="education-item">
                <span className="education-year">{edu.year}</span>
                <p className="education-institution">{edu.institution}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Author;