import React from 'react';
import { useCart } from '../context/CartContext';

const Checkout = ({ user, setIsLoginOpen }) => {
  const { state } = useCart();
  
  const calculateTotal = (items) => {
    return items.reduce((total, item) => total + (item.price * item.quantity), 0);
  };
  
  const handleCheckout = () => {
    if (!user) {
      setIsLoginOpen(true);
      return;
    }
    
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: calculateTotal(state.items) * 100,
      currency: "INR",
      name: "Sugandhini Publications",
      description: "Book Purchase",
      handler: function (response) {
        alert("Payment Successful!");
      },
      prefill: {
        email: user.email
      },
      theme: {
        color: "#2C3E50"
      }
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  return (
    <button 
      className="checkout-btn"
      onClick={handleCheckout}
    >
      Proceed to Checkout
    </button>
  );
};

export default Checkout;