import React from 'react';
import './UserProfileDetails.css';
import { FaEnvelope, FaPhone, FaCalendar, FaHome } from 'react-icons/fa';

const UserProfileDetails = ({ user }) => {
  if (!user) {
    return (
      <div className="profile-details-container">
        <h2>Profile Not Available</h2>
        <p>Please log in to view your profile.</p>
      </div>
    );
  }

  return (
    <div className="profile-details-container">
      <h2>My Profile</h2>
      
      <div className="profile-info-card">
        <div className="profile-header">
          <div className="profile-avatar-xl">
            {user.name.charAt(0).toUpperCase()}
          </div>
          <div className="basic-info">
            <h3>{user.name}</h3>
            <span className="user-type">{user.type}</span>
          </div>
        </div>

        <div className="profile-details-grid">
          <div className="detail-item">
            <FaEnvelope className="detail-icon" />
            <div className="detail-content">
              <label>Email</label>
              <p>{user.email}</p>
            </div>
          </div>
          
          <div className="detail-item">
            <FaPhone className="detail-icon" />
            <div className="detail-content">
              <label>Phone</label>
              <p>{user.phone || 'Not provided'}</p>
            </div>
          </div>

          <div className="detail-item">
            <FaCalendar className="detail-icon" />
            <div className="detail-content">
              <label>Date of Birth</label>
              <p>{user.dob || 'Not provided'}</p>
            </div>
          </div>

          <div className="detail-item">
            <FaHome className="detail-icon" />
            <div className="detail-content">
              <label>Address</label>
              <p>{user.address || 'Not provided'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileDetails; 