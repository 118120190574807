import React, { useState } from 'react';
import './Footer.css';
import HeloavyLogo from './Heloavy_logo_icon_800X650_transparent.png';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState({ type: '', message: '' });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/newsletter/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setStatus({ type: 'success', message: 'Successfully subscribed!' });
                setEmail('');
            } else {
                setStatus({ type: 'error', message: data.error });
            }
        } catch (error) {
            setStatus({ type: 'error', message: 'Something went wrong. Please try again.' });
        }
    };

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-info">
                    <h3 className="footer-title">Sugandhini Publications</h3>
                    <p className="footer-copyright">&copy; {new Date().getFullYear()} All rights reserved.</p>
                </div>
                
                <div className="footer-section newsletter">
                    <h4>Stay Updated</h4>
                    <p className="newsletter-subtitle">Subscribe to our newsletter</p>
                    <form className="newsletter-form" onSubmit={handleSubmit}>
                        <input 
                            type="email" 
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            aria-label="Newsletter email signup"
                            required
                        />
                        <button type="submit" className="subscribe-btn">
                            Subscribe
                        </button>
                    </form>
                    {status.message && (
                        <p className={`status-message ${status.type}`}>
                            {status.message}
                        </p>
                    )}
                </div>

                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <a href="/about">About Us</a>
                    <a href="/contact">Contact</a>
                    <a href="/privacy">Privacy Policy</a>
                </div>

                <div className="footer-section">
                    <h4>Connect</h4>
                    <a href="https://youtube.com/@sugandhinipublications">YouTube</a>
                    <a href="https://linkedin.com">LinkedIn</a>
                    <a href="https://facebook.com">Facebook</a>
                </div>

                <div className="button-container">
                    <button className="brutalist-button">
                        <img
                            className="heloavy-logo"
                            src={HeloavyLogo}
                            alt="Heloavy Logo"
                        />
                        <div className="button-text">
                            <span>Powered By</span>
                            <span className="brand-name">HELOAVY</span>
                        </div>
                    </button>
                </div>
            </div>
        </footer>
    );
};

export default Footer;