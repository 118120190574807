import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png';
import Login from './Login';
import UserProfile from './UserProfile';
import CartIcon from './CartIcon';
import { FaBars, FaTimes } from 'react-icons/fa';

function Header({ user, setUser, setIsLoginOpen }) {
  const [scrolled, setScrolled] = useState(false);
  const [isRegularLoginOpen, setIsRegularLoginOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [isOpen]);

  const handleLogout = () => {
    setUser(null);
  };

  const handleLoginSuccess = (userData) => {
    setUser(userData);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={`header ${scrolled ? 'scrolled' : ''}`}>
      <div className="logo-container">
        <Link to="/" className="logo">
          <img src={logo} alt="Sugandhini Logo" className="logo-image" />
        </Link>
      </div>
      
      <div className="nav-container">
        <ul className="nav-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/book">Book</Link></li>
          <li><Link to="/author">Author</Link></li>
          <li><Link to="/about">About Us</Link></li>
          <li><Link to="/blog">Blog</Link></li>
          <li><Link to="/contact">Get in Touch</Link></li>
        </ul>
      </div>

      <div className="right-section">
        <Link to="/cart">
          <CartIcon />
        </Link>
        <div className="auth-buttons">
          {!user ? (
            <button className="login-btn" onClick={() => setIsRegularLoginOpen(true)}>
              Login/Signup
            </button>
          ) : (
            <UserProfile user={user} onLogout={handleLogout} />
          )}
        </div>
      </div>

      <Login 
        isOpen={isRegularLoginOpen} 
        onClose={() => setIsRegularLoginOpen(false)} 
        onLoginSuccess={handleLoginSuccess}
      />

      
    </header>
  );
}

export default Header;
