import React, { useEffect, useState } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { useCart } from '../context/CartContext';
import './CartIcon.css';

const CartIcon = () => {
  const { state } = useCart();
  const [prevCount, setPrevCount] = useState(state.items.length);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const currentCount = state.items.length;
    if (currentCount !== prevCount) {
      setIsAnimating(true);
      const timer = setTimeout(() => {
        setPrevCount(currentCount);
        setIsAnimating(false);
      }, 150);
      return () => clearTimeout(timer);
    }
  }, [state.items.length, prevCount]);

  return (
    <div className="cart-icon-wrapper">
      <FaShoppingCart size={24} />
      {state.items.length > 0 && (
        <div className="cart-count">
          <span className={isAnimating ? 'cd-cart__count--leave' : ''}>
            {prevCount}
          </span>
          {isAnimating && (
            <span className="cd-cart__count--update">
              {state.items.length}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default CartIcon; 