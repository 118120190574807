import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './EditProfile.css';
import { FaUser, FaEnvelope, FaPhone, FaCalendar, FaHome, FaSave } from 'react-icons/fa';

const EditProfile = ({ userDetails, onSave }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: userDetails?.name || '',
    email: userDetails?.email || '',
    phone: userDetails?.phone || '',
    dob: userDetails?.dob || '',
    address: userDetails?.address || ''
  });

  if (!userDetails) {
    return (
      <div className="edit-profile-container">
        <h2>Access Denied</h2>
        <p>Please log in to edit your profile.</p>
        <button onClick={() => navigate('/')} className="save-button">
          Return to Home
        </button>
      </div>
    );
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    navigate('/profile');
  };

  return (
    <div className="edit-profile-container">
      <h2>Edit Profile</h2>
      
      <form onSubmit={handleSubmit} className="edit-profile-form">
        <div className="form-group">
          <label>
            <FaUser className="input-icon" />
            Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>
            <FaEnvelope className="input-icon" />
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>
            <FaPhone className="input-icon" />
            Phone
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>
            <FaCalendar className="input-icon" />
            Date of Birth
          </label>
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>
            <FaHome className="input-icon" />
            Address
          </label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleChange}
            rows="3"
          />
        </div>

        <button type="submit" className="save-button">
          <FaSave /> Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditProfile; 