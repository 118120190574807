import React, { useRef } from 'react';
import './Home.css';
import { FaBook, FaGraduationCap, FaStar, FaLaptop } from 'react-icons/fa';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  const testimonialRef = useRef(null);
  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "Parent",
      text: "The books have made learning Kannada fun and engaging for my child. The digital content is especially helpful!",
      image: "https://randomuser.me/api/portraits/women/1.jpg"
    },
    {
      name: "Dr. Ramesh Kumar",
      role: "Education Specialist",
      text: "The curriculum design aligns perfectly with NEP 2020 guidelines. A well-thought-out learning approach.",
      image: "https://randomuser.me/api/portraits/men/1.jpg"
    },
    {
      name: "Priya Sharma",
      role: "Teacher",
      text: "The bilingual approach makes it easy to teach Kannada to non-native speakers. Excellent resource!",
      image: "https://randomuser.me/api/portraits/women/2.jpg"
    },

    {
      name: "Allan John",
      role: "Teacher",
      text: "A must-have for parents. The format makes it so simple for us to teach Kannada even as non-native speakers. Digital content keeps our child interested and learning quickly.",
      image: "https://randomuser.me/api/portraits/men/2.jpg"
    },

    {
      name: "Payal Jain",
      role: "Learner",
      text: "The best Kannada learning resource we’ve tried! The mix of books and interactive digital content makes language learning exciting.",
      image: "https://randomuser.me/api/portraits/women/2.jpg"
    },

    {
      name: "Dr.Ram S",
      role: "Parent",
      text: "Ideal for young Kannada learners. The bilingual support is incredibly helpful, and the digital exercises keep my child engaged.",
      image: "https://randomuser.me/api/portraits/men/2.jpg"
    }

  ];

  const learningStages = [
    { level: "Crawlers", grade: "Preschool", description: "Identify the letters and picture reading" },
    { level: "Walkers", grade: "LKG", description: "Able to form the letters upto Swaragalu" },
    { level: "Runners", grade: "UKG", description: "Capable of writing simple words" },
    { level: "Flyers", grade: "1st std", description: "Ability to read and write simple sentences" },
    { level: "Gliders", grade: "2nd std", description: "Accomplish basic fundamentals and confident to read and write independently" }
  ];

  const featuredProducts = [
    { icon: <FaBook />, title: "Academic Publishing", description: "Quality textbooks for all grades" },
    { icon: <FaGraduationCap />, title: "Curriculum Development", description: "Structured learning paths" },
    { icon: <FaStar />, title: "Research Journals", description: "Latest educational research" },
    { icon: <FaLaptop />, title: "Digital Resources", description: "eBooks and online courses" }
  ];

  const handleAnimationEnd = () => {
    const container = testimonialRef.current;
    if (container) {
      container.style.transition = 'none';
      container.style.transform = 'translateX(0)';
      // Force reflow
      void container.offsetHeight;
      container.style.transition = 'transform 0.3s ease';
    }
  };

  return (
    <div className="home">
      <section className="hero">
        <div className="hero-content animate__animated animate__fadeIn">
          <h1 className="animate__animated animate__slideInDown">
            SUGANDHINI PUBLICATIONS
            <div className="hero-underline"></div>
          </h1>
          <p className="tagline animate__animated animate__fadeIn animate__delay-1s">
            "If You Dream It, You Can Do It"
          </p>
          <button 
            className="cta-button animate__animated animate__bounceIn animate__delay-2s"
            onClick={() => navigate('/book')}
          >
            Explore Our Collection
            <span className="button-arrow">
              <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        </div>
        <div className="hero-overlay">
          <div className="floating-shapes">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
          </div>
        </div>
      </section>

      <ScrollAnimation animateIn="fadeIn">
        <section className="featured-products">
          <h2 className="section-title">
            Our Products
            <div className="title-accent"></div>
          </h2>
          <div className="products-grid">
            {featuredProducts.map((product, index) => (
              <div 
                className="product-card"
                key={index}
                style={{animationDelay: `${index * 0.2}s`}}
              >
                <div className="product-icon">{product.icon}</div>
                <h3>{product.title}</h3>
                <p>{product.description}</p>
                <div className="card-overlay">
                  <button className="learn-more">Learn More</button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn">
        <section className="why-choose-us">
          <div className="section-header">
            <div className="header-decoration"></div>
            <h2>Why Choose Us</h2>
            <div className="header-decoration"></div>
          </div>

          <div className="features-grid">
            <div className="feature-card">
              <div className="feature-icon">
                <i className="fas fa-book-reader"></i>
              </div>
              <h3>Expert Content</h3>
              <p>Curated by education specialists with decades of experience</p>
              <div className="feature-hover">
                <span className="learn-more">Learn More <i className="fas fa-arrow-right"></i></span>
              </div>
            </div>

            <div className="feature-card">
              <div className="feature-icon">
                <i className="fas fa-graduation-cap"></i>
              </div>
              <h3>Research-Based</h3>
              <p>Materials developed using latest educational research</p>
              <div className="feature-hover">
                <span className="learn-more">Learn More <i className="fas fa-arrow-right"></i></span>
              </div>
            </div>

            <div className="feature-card">
              <div className="feature-icon">
                <i className="fas fa-child"></i>
              </div>
              <h3>Child-Centric</h3>
              <p>Designed specifically for young minds' development</p>
              <div className="feature-hover">
                <span className="learn-more">Learn More <i className="fas fa-arrow-right"></i></span>
              </div>
            </div>

            <div className="feature-card">
              <div className="feature-icon">
                <i className="fas fa-chart-line"></i>
              </div>
              <h3>Proven Results</h3>
              <p>Track record of improving learning outcomes</p>
              <div className="feature-hover">
                <span className="learn-more">Learn More <i className="fas fa-arrow-right"></i></span>
              </div>
            </div>
          </div>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn">
        <section className="learning-stages">
          <h2>Learning Stages</h2>
          <div className="stages-grid">
            {learningStages.map((stage, index) => (
              <div 
                key={index} 
                className="stage-card"
                style={{
                  animationDelay: `${index * 0.3}s`,
                  backgroundColor: `hsl(${index * 45}, 95%, 95%)`
                }}
              >
                <div className="book-icon">📌</div>
                <div className="stage-content">
                  <h3>Level-{index + 1} {stage.level}</h3>
                  <h4>{stage.grade}</h4>
                  <p>{stage.description}</p>
                </div>
                <div className="sparkles">✨</div>
              </div>
            ))}
          </div>
        </section>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn">
        <section className="experience-corner">
          <h2>What Parents & Teachers Say</h2>
          <div className="testimonials-carousel">
            <div 
              className="testimonials-container" 
              ref={testimonialRef}
              onAnimationEnd={handleAnimationEnd}
            >
              {testimonials.map((testimonial, index) => (
                <div key={`${testimonial.name}-${index}`} className="testimonial-card">
                  <img 
                    src={testimonial.image} 
                    alt={testimonial.name} 
                    className="testimonial-image"
                  />
                  <p className="testimonial-text">{testimonial.text}</p>
                  <h4 className="testimonial-name">{testimonial.name}</h4>
                  <p className="testimonial-role">{testimonial.role}</p>
                </div>
              ))}
              {testimonials.map((testimonial, index) => (
                <div key={`${testimonial.name}-${index}-duplicate`} className="testimonial-card">
                  <img 
                    src={testimonial.image} 
                    alt={testimonial.name} 
                    className="testimonial-image"
                  />
                  <p className="testimonial-text">{testimonial.text}</p>
                  <h4 className="testimonial-name">{testimonial.name}</h4>
                  <p className="testimonial-role">{testimonial.role}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </ScrollAnimation>
    </div>
  );
}

export default Home;