import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { 
  FaChartLine, 
  FaCalendarAlt, 
  FaUsers, 
  FaBook, 
  FaCog,
  FaSignOutAlt
} from 'react-icons/fa';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('analytics');
  const [analyticsData] = useState({
    totalUsers: 1250,
    totalSessions: 450,
    pendingRequests: 23,
    completedSessions: 427,
    recentBookings: [
      { id: 1, name: "John Doe", date: "2024-03-20", status: "Confirmed" },
      { id: 2, name: "Jane Smith", date: "2024-03-21", status: "Pending" }
    ]
  });

  const handleLogout = () => {
    // Add any cleanup logic here (clear tokens, etc.)
    navigate('/');
  };

  // Chart Data
  const lineChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'User Growth',
        data: [650, 750, 850, 950, 1150, 1250],
        borderColor: '#3498db',
        tension: 0.4,
        fill: true,
        backgroundColor: 'rgba(52, 152, 219, 0.1)',
      }
    ]
  };

  const barChartData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Sessions Booked',
        data: [12, 19, 15, 8, 14, 10, 7],
        backgroundColor: '#2ecc71',
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  const renderContent = () => {
    switch(activeTab) {
      case 'analytics':
        return (
          <div className="dashboard-content">
            <div className="stats-grid">
              <div className="stat-card">
                <h3>Total Users</h3>
                <p className="stat-number">{analyticsData.totalUsers}</p>
                <div className="stat-trend positive">+12% ↑</div>
              </div>
              <div className="stat-card">
                <h3>Total Sessions</h3>
                <p className="stat-number">{analyticsData.totalSessions}</p>
                <div className="stat-trend positive">+8% ↑</div>
              </div>
              <div className="stat-card">
                <h3>Pending Requests</h3>
                <p className="stat-number">{analyticsData.pendingRequests}</p>
                <div className="stat-trend neutral">0% →</div>
              </div>
              <div className="stat-card">
                <h3>Completed Sessions</h3>
                <p className="stat-number">{analyticsData.completedSessions}</p>
                <div className="stat-trend positive">+15% ↑</div>
              </div>
            </div>

            <div className="charts-grid">
              <div className="chart-card">
                <h3>User Growth Trend</h3>
                <Line data={lineChartData} options={chartOptions} />
              </div>
              
              <div className="chart-card">
                <h3>Weekly Session Distribution</h3>
                <Bar data={barChartData} options={chartOptions} />
              </div>
            </div>

            <div className="recent-activity">
              <h3>Recent Session Bookings</h3>
              <div className="activity-list">
                {analyticsData.recentBookings.map(booking => (
                  <div key={booking.id} className="activity-item">
                    <div className="activity-info">
                      <h4>{booking.name}</h4>
                      <p>Date: {booking.date}</p>
                    </div>
                    <span className={`status ${booking.status.toLowerCase()}`}>
                      {booking.status}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      // Add other cases for different tabs
      default:
        return null;
    }
  };

  return (
    <div className="admin-dashboard">
      <aside className="dashboard-sidebar">
        <div className="sidebar-header">
          <h2>Admin Panel</h2>
        </div>
        <nav className="sidebar-nav">
          <button 
            className={`nav-item ${activeTab === 'analytics' ? 'active' : ''}`}
            onClick={() => setActiveTab('analytics')}
          >
            <FaChartLine /> Analytics
          </button>
          <button 
            className={`nav-item ${activeTab === 'sessions' ? 'active' : ''}`}
            onClick={() => setActiveTab('sessions')}
          >
            <FaCalendarAlt /> Session Management
          </button>
          <button 
            className={`nav-item ${activeTab === 'users' ? 'active' : ''}`}
            onClick={() => setActiveTab('users')}
          >
            <FaUsers /> User Management
          </button>
          <button 
            className={`nav-item ${activeTab === 'books' ? 'active' : ''}`}
            onClick={() => setActiveTab('books')}
          >
            <FaBook /> Book Inventory
          </button>
          <button 
            className={`nav-item ${activeTab === 'settings' ? 'active' : ''}`}
            onClick={() => setActiveTab('settings')}
          >
            <FaCog /> Settings
          </button>
        </nav>
        <button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt /> Logout
        </button>
      </aside>

      <main className="dashboard-main">
        <header className="dashboard-header">
          <h1>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h1>
          <div className="admin-profile">
            <span>Admin Name</span>
            <div className="admin-avatar">A</div>
          </div>
        </header>
        {renderContent()}
      </main>
    </div>
  );
};

export default AdminDashboard; 